import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'

import Button from '@components/Button'

import styles from './index.module.css'
import { IconX } from '@tabler/icons-react'

export { usePopupHandlers } from './usePopupHandler'

interface PopupProps {
  title?: string
  onClose?: () => void
  className?: string | null
  containerStyle?: string | null
  closeButtonStyle?: string | null
  allowCloseOnEsc?: boolean
  allowCloseOnMissClick?: boolean
  allowOutsideButtons?: boolean
}

const Popup: React.FC<React.PropsWithChildren<PopupProps>> = ({
  children,
  title,
  className,
  containerStyle,
  closeButtonStyle,
  allowCloseOnEsc = true,
  allowCloseOnMissClick = true,
  onClose,
  allowOutsideButtons = false,
}) => {
  const [container, setContainer] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const el = document.getElementById('popup-container')

    if (el) {
      setContainer(el)
    }
  }, [])

  const handleClose: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.stopPropagation()
      if (onClose) {
        onClose()
      }
    },
    [onClose],
  )

  const stopPropagation: React.MouseEventHandler<HTMLElement> = useCallback((e) => {
    e.stopPropagation()
  }, [])

  const handleKeyDown = (e: KeyboardEvent) => {
    e.stopPropagation()
    if (e.key === 'Escape') {
      onClose?.()
    }
  }

  useEffect(() => {
    if (allowCloseOnEsc) {
      document.addEventListener('keydown', handleKeyDown)
    }
    document.body.style.overflow = 'hidden'

    return () => {
      if (allowCloseOnEsc) {
        document.removeEventListener('keydown', handleKeyDown)
      }
      document.body.style.overflow = 'auto'
    }
  }, [onClose])

  return container
    ? ReactDOM.createPortal(
        <div
          className={twMerge(
            cx('fixed z-[25] flex bg-gray-900 bg-opacity-60 w-full h-full text-black justify-center', containerStyle),
          )}
          onClick={allowCloseOnMissClick ? handleClose : undefined}
        >
          <div
            className={twMerge(
              cx(
                'max-h-[90%] rounded-xl bg-white p-4 opacity-100 max-w-md w-full m-auto relative',
                !allowOutsideButtons && 'overflow-auto',
                styles.popup,
                className,
              ),
            )}
            onClick={stopPropagation}
          >
            {title && <h4 className="text-lg leading-6 font-semibold border-b mb-3 pb-2">{title}</h4>}
            {onClose && (
              <Button
                type="contained"
                onClick={handleClose}
                className={cx('absolute right-2.5 top-2.5 z-50 p-0 min-h-0 h-auto', closeButtonStyle)}
              >
                <IconX className={cx(allowOutsideButtons ?'text-slate-950 h-8 w-8' : 'h-5 w-5')} />
              </Button>
            )}
            {children}
          </div>
        </div>,
        container,
      )
    : null
}

export default Popup
